// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let mvHeight = jQuery(".mv").height();
    let documentHeight = jQuery(document).height();

    if (mvHeight < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  jQuery(".g-nav-item-link.child").on("click", function(e) {
    let drawer = jQuery(".js-drawer");
    let targetClass = jQuery(drawer).attr("data-target");
    let ariaControls = jQuery(drawer).attr("aria-controls");

    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");
    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(drawer).attr("aria-expanded") === "true") {
      jQuery(drawer).attr("aria-expanded", "false");
    } else {
      jQuery(drawer).attr("aria-expanded", "ture");
    }

    /*     jQuery(this).toggleClass("active");
    jQuery(this)
      .closest(".menu-second")
      .slideToggle();
    jQuery(this)
      .not(jQuery(this).closest(".g-nav-item.child"))
      .children(".menu-second")
      .slideUp();
    jQuery(this)
      .not(jQuery(this).hasClass(".active"))
      .toggleClass("active"); */
  });
  /*   } */

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    if (jQuery(".leftmenu-ttl").hasClass("on")) {
      jQuery(".left-menu-list").slideToggle();
      jQuery(".leftmenu-ttl").toggleClass("on");
    }
    return false;
  });

  jQuery(".sp-btn").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
  });
});

jQuery('a[href^="#"]:not(.no_somooth)').click(function() {
  let id = jQuery(this).attr("href");
  let speed = 300;
  let target = jQuery("#" == id ? "html" : id);
  let position = jQuery(target).offset().top;
  let headerHeight = jQuery("header").innerHeight();
  if (window.matchMedia("(min-width: 769px)").matches) {
    position = jQuery(target).offset().top - 100;
    a = "a1";
  } else {
    //　ここに「.leftmenu」が存在した場合
    if (jQuery(".leftmenu").length) {
      position = jQuery(target).offset().top - (80 + 32);
    } else {
      position = jQuery(target).offset().top - 80;
    }
    a = "a2";
  }
  /*   if ("fixed" !== jQuery("header").css("position")) {
    position = jQuery(target).offset().top;
    a = "a3";
  } */

  jQuery("html, body").animate(
    {
      scrollTop: position
    },
    speed
  );
  return false;
});

jQuery(function() {
  // aimation呼び出し
  if (jQuery(".js-scroll-trigger").length) {
    scrollAnimation();
  }

  // aimation関数
  function scrollAnimation() {
    jQuery(window).scroll(function() {
      jQuery(".js-scroll-trigger").each(function() {
        let position = jQuery(this).offset().top,
          scroll = jQuery(window).scrollTop(),
          windowHeight = jQuery(window).height();

        if (scroll > position - windowHeight + 200) {
          jQuery(this).addClass("active");
        }
      });
    });
  }
  jQuery(window).trigger("scroll");
});

//wait
jQuery(function() {
  "use waits";

  var dialog = document.getElementById("dialog");
  var waits = document.getElementsByClassName("js-wait");
  var cancel = document.getElementById("cancel");
  var scrollpos;

  if (waits) {
    for (let i = 0; i < waits.length; i++) {
      waits[i].addEventListener(
        "click",
        function() {
          dialog.style.visibility = "visible";
          dialog.style.opacity = 1;
          //scrollpos = jQuery(window).scrollTop();
          jQuery("body").addClass("fixed");
          //.css({ overflow: hidden });
        },
        false
      );
    }

    /*     cancel.addEventListener("click", function() {
      jQuery("body").removeClass("fixed");
      //.css({ overflow: auto });
      //window.scrollTo(0, scrollpos);
      dialog.style.visibility = "hidden";
      dialog.style.opacity = 0;
    }); */
  }
});

if (window.matchMedia("(min-width: 769px)").matches) {
  /* ウィンドウサイズが 768px以上の場合に有効にしたい処理*/
}
var _window = jQuery(window),
  _header = jQuery("header"),
  heroBottom;

_window.on("scroll", function() {
  heroBottom1 = jQuery(".mv").height() - 120;
  heroBottom2 = jQuery(".header-logo").height();
  heroBottom = heroBottom1 - heroBottom2;
  if (_window.scrollTop() > heroBottom1) {
    _header.addClass("transform");
  } else {
    _header.removeClass("transform");
  }
});
_window.trigger("scroll");

var urlHash = location.hash;
if (urlHash) {
  jQuery("body,html")
    .stop()
    .scrollTop(0);

  setTimeout(function() {
    var target = jQuery(urlHash);

    if (window.matchMedia("(min-width: 769px)").matches) {
      var position = target.offset().top - 60;
    } else {
      var position = target.offset().top - 100;
    }
    //ランダムっぽいのでどちらにも対応
    jQuery("body,html")
      .stop()
      .animate({ scrollTop: position }, 300);
    console.log(header);
    console.log(position);
  }, 100);
}
if (window.matchMedia("(max-width: 768px)").matches) {
  jQuery(function() {
    jQuery(".warry-arrows").each(function() {
      jQuery(this).on("click", function() {
        jQuery(this).toggleClass("on");
        jQuery(this)
          .next(".warry-content")
          .slideToggle();
        return false;
      });
    });
  });
}

jQuery(document).ready(function() {
  // /jQuery(".floating").hide(); //ボタンを非表示にする
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(window).scrollTop(); // スクロール上部の位置
    let headerHeight = jQuery("header").innerHeight();

    scrollHeight = jQuery(document).height(); //ドキュメントの高さ
    scrollPosition = jQuery(window).height() + jQuery(window).scrollTop(); //現在地
    footHeight = jQuery("footer").outerHeight(true); //止めたい位置の高さ(今回はfooter)
    stopHeight = footHeight;

    bottomHeight = footHeight;

    if (window.matchMedia("(min-width: 751px)").matches) {
      if (scrollHeight - scrollPosition <= stopHeight) {
        //ドキュメントの高さと現在地の差がfooterの高さ以下の時
        jQuery(".floating").css({
          position: "absolute", //pisitionをabsoluteに変更
          bottom: bottomHeight
        });
      } else {
        //それ以外の場合は
        jQuery(".floating").css({
          position: "fixed", //固定表示
          bottom: "0px"
        });
      }
    }

    scrollHeight = jQuery(document).height(); //ドキュメントの高さ
    scrollPosition = jQuery(window).height() + jQuery(window).scrollTop(); //現在地
    footHeight = jQuery("footer").innerHeight(); //止めたい位置の高さ(今回はfooter)
    stopHeight = footHeight;
  });
});
